import React, { useState } from 'react';

import { observer } from 'mobx-react';
import numbro from 'numbro';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ApiError, MegaBoxMarketingService } from 'api/client';
import { useStore } from 'store';

import { Typography } from 'components/dataDisplay';
import { Modal } from 'components/feedback';
import { Button } from 'components/inputs';

import styles from './InstallMegaBoxPlaceModal.module.scss';

const InstallMegaBoxPlaceModal: React.FC = () => {
  const { t } = useTranslation();
  const { placeId } = useParams();
  const { megaBoxMarketingStore, layoutStore } = useStore();
  const { desk, installPlaceModalConfig, closeInstallPlaceModal } = megaBoxMarketingStore;

  const [isInstallLoading, setIsInstallLoading] = useState(false);

  const handleClose = () => {
    closeInstallPlaceModal();
  };

  const handleInstall = async () => {
    try {
      setIsInstallLoading(true);
      await MegaBoxMarketingService.buyMegaBoxClone(
        installPlaceModalConfig?.placeId ?? desk?.items[0]?.id!,
        installPlaceModalConfig?.place as 'L' | 'R'
      );

      if (placeId) {
        megaBoxMarketingStore.getDeskByPlaceId(parseInt(placeId));
      } else {
        megaBoxMarketingStore.getDesk(desk?.current.id!);
      }
      handleClose();
    } catch (error) {
      layoutStore.openFeedbackModal((error as ApiError)?.body?.message || t('shared.errors.unknownError'));
    } finally {
      setIsInstallLoading(false);
    }
  };

  return (
    <Modal
      className={styles.InstallMegaBoxPlaceModal}
      variant="unwrapped"
      isOpen={!!installPlaceModalConfig}
      onClose={handleClose}
    >
      <Typography className={styles.title} variant="heading6" element="h2" color="white">
        {t('modals.installMegaBoxPlace.title')}
      </Typography>

      <Typography className={styles.text} variant="paragraph2" element="p" color="boneWhite">
        {t('modals.installMegaBoxPlace.text')}
      </Typography>

      <Typography className={styles.label} variant="paragraph1" element="p" color="boneWhite">
        {t('modals.installMegaBoxPlace.price')}:
      </Typography>
      <Typography className={styles.price} variant="heading6" element="p" color="boneWhite">
        {numbro(desk?.current.price).format()} <span>ВВ</span>
      </Typography>

      <div className={styles.controls}>
        <Button
          className={styles.button}
          variant="outlined"
          color="lightGray"
          disabled={isInstallLoading}
          onClick={handleClose}
        >
          {t('shared.controls.cancel')}
        </Button>
        <Button className={styles.button} isLoading={isInstallLoading} onClick={handleInstall}>
          {t('modals.installMegaBoxPlace.submit')}
        </Button>
      </div>
    </Modal>
  );
};

export default observer(InstallMegaBoxPlaceModal);
