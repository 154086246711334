/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuyMegaboxPlaceRequest } from '../models/BuyMegaboxPlaceRequest';
import type { DeskItemPlaces6 } from '../models/DeskItemPlaces6';
import type { DeskListItem4 } from '../models/DeskListItem4';
import type { FoundDeskUser } from '../models/FoundDeskUser';
import type { LineMegaBoxStatisticsItem } from '../models/LineMegaBoxStatisticsItem';
import type { MarketingStatistics2 } from '../models/MarketingStatistics2';
import type { PreOrderDeskListItem } from '../models/PreOrderDeskListItem';
import type { UserInstalledClone4 } from '../models/UserInstalledClone4';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MegaBoxMarketingService {
  /**
   * Gets the list of desks in the MegaBox marketing for pre-order
   * @returns any Returns the list of desks in the MegaBox marketing for pre-order
   * @throws ApiError
   */
  public static getMegaBoxPreOrderDeskList(): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<PreOrderDeskListItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/megabox/pre-order/desk/list',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Buys a buy pre-order in the MegaBox marketing
   * @param deskId MegaBox Desk ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static buyMegaBoxPreOrder(deskId: number, requestBody: BuyMegaboxPlaceRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/marketing/megabox/pre-order/buy/{deskId}',
      path: {
        deskId: deskId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Gets pre-order desk statistics in MegaBox marketing
   * @param offset Offset
   * @param limit Limit
   * @param line line id
   * @param username Username query
   * @param isBuy Filter for users who buy MegaBox
   * @returns any Returns desk statistics in MegaBox marketing
   * @throws ApiError
   */
  public static getMegaBoxPreOrderStatistics(
    offset?: number,
    limit: number = 12,
    line: number = 1,
    username?: string,
    isBuy?: boolean
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<LineMegaBoxStatisticsItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/megabox/pre-order/statistics',
      query: {
        offset: offset,
        limit: limit,
        line: line,
        username: username,
        isBuy: isBuy,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets the list of desks in the MegaBox marketing
   * @returns any Returns the list of desks in the MegaBox marketing
   * @throws ApiError
   */
  public static getMegaBoxDeskList(): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<DeskListItem4>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/megabox/desk/list',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets the structure of the desk.
   * @param deskId MegaBox Desk ID
   * @returns any Returns the structure of the desk.
   * @throws ApiError
   */
  public static showMegaBoxDesk(deskId: number): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces6;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/megabox/desk/{deskId}/show',
      path: {
        deskId: deskId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the desk structure starting from the given place.
   * @param placeId MegaBox Place ID
   * @returns any Returns the desk structure starting from the given place.
   * @throws ApiError
   */
  public static showMegaBoxDeskFromPlace(placeId: number): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces6;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/megabox/place/{placeId}/show',
      path: {
        placeId: placeId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the desk structure starting on next level.
   * @param placeId MegaBox Place ID
   * @returns any Returns the desk structure starting on next level.
   * @throws ApiError
   */
  public static showMegaBoxNextLevelJump(placeId: number): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces6;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/megabox/place/{placeId}/next-level-jump',
      path: {
        placeId: placeId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the desk structure starting on current level.
   * @param placeId MegaBox Place ID
   * @returns any Returns the desk structure starting on current level.
   * @throws ApiError
   */
  public static showMegaBoxCurrentLevelJump(placeId: number): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces6;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/megabox/place/{placeId}/current-level-jump',
      path: {
        placeId: placeId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Buys places or clones in MegaBox marketing with automatic placement in the matrix
   * @param deskId MegaBox Desk ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static buyMegaBoxPlaces(deskId: number, requestBody: BuyMegaboxPlaceRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/marketing/megabox/{deskId}/buy/places',
      path: {
        deskId: deskId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Buys a clone in MegaBox marketing with placement in a given place
   * @param placeId MegaBox Parent Place ID
   * @param position Place Position
   * @returns void
   * @throws ApiError
   */
  public static buyMegaBoxClone(placeId: number, position: 'L' | 'R'): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/marketing/megabox/buy/clone/{placeId}/{position}',
      path: {
        placeId: placeId,
        position: position,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Gets a list of my installed clones in MegaBox marketing.
   * @param deskId MegaBox Desk ID
   * @param offset Offset
   * @param limit Limit
   * @returns any Returns a list of my installed clones in MegaBox marketing.
   * @throws ApiError
   */
  public static myInstalledClonesInMegaBox(
    deskId: number,
    offset?: number,
    limit: number = 50
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<UserInstalledClone4>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/megabox/desk/{deskId}/my-clones',
      path: {
        deskId: deskId,
      },
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets desk statistics in MegaBox marketing
   * @param offset Offset
   * @param limit Limit
   * @param filterDesk Desk ids
   * @param filterLine Lines
   * @param filterClones Show only clones
   * @param filterPlacesLeft Places before closing
   * @param filterUsername Username query
   * @returns any Returns desk statistics in MegaBox marketing
   * @throws ApiError
   */
  public static showMegaBoxStatistics(
    offset?: number,
    limit: number = 12,
    filterDesk?: Array<number>,
    filterLine?: Array<1 | 2 | 3 | 4 | 5>,
    filterClones?: boolean,
    filterPlacesLeft?: Array<1 | 2 | 3>,
    filterUsername?: string
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<MarketingStatistics2>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/megabox/desk/statistics',
      query: {
        offset: offset,
        limit: limit,
        'filter[desk][]': filterDesk,
        'filter[line][]': filterLine,
        'filter[clones]': filterClones,
        'filter[placesLeft][]': filterPlacesLeft,
        'filter[username]': filterUsername,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Searches for a user in the desk by username in MegaBox marketing
   * @param deskId MegaBox Desk ID
   * @param q Username
   * @param limit
   * @returns any Returns found users on the desk
   * @throws ApiError
   */
  public static searchUserInMegaBoxMarketing(
    deskId: number,
    q: string,
    limit: number = 10
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<FoundDeskUser>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/megabox/desk/{deskId}/search/user',
      path: {
        deskId: deskId,
      },
      query: {
        q: q,
        limit: limit,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }
}
